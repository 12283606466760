import React from "react";

import {
  Box,
  Typography,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";

const GeneralBlock = styled("div")(({ theme }) => ({
   paddingLeft:"24px",
   cursor:"default",
   "& .inputB": {
    width: "97%",
    height: "44px",
    borderRadius: "8px",
    pointerEvents: "none",
    backgroundColor: "#F3F4F6", 
    color: "#BCC1CA", 
    border: "1px solid transparent",
    marginTop: "10px",
    padding: "10px 8px",
    fontSize:"16px",
    fontWeight:400,
    lineHeight:"24px",
    boxShadow:"0 0 0 1000px #F3F4F6 inset !important"
   },
   "& .label-text": {
    fontWeight: 700,
    fontSize: "14px",
    color:"#272727"
   },
   "& .inputB-edit": {
    width: "97%",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid transparent",
    backgroundColor: "#F3F4F6", 
    marginTop: "10px",
    padding: "10px",
    color:"#171A1F",
    fontSize:"16px",
    fontWeight:400,
    lineHeight:"24px",
    boxShadow:"0 0 0 1000px #F3F4F6 inset !important"
   },
   "& .input:focus": {
    borderColor:"#FF6600",
    outline: "none", 
   },
   "& .comm-text": {
    fontSize: "16px",
    fontWeight: 600,
    color:"#475569"
   },
   "& .comm-input[type=number]::-webkit-inner-spin-button,.comm-input[type=number]::-webkit-outer-spin-button": { 
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    "appearance": "none",
    margin: 0,
  },
  "& .comm-input[type=number]" :{
    "-moz-appearance": "textfield",
  },
  "& .dropdown-item:hover": {
    backgroundColor: "#FFE0CC !important",
    borderRadius:"8px !important",
  },
  "& .error-msg": {
    color: "red",
    marginTop: "5px",
    fontSize: "12px"
  },
  }))
// Customizable Area End

import BillingAddressController, {Props} from "./BillingAdressController";

export default class BillingAddress extends BillingAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 

    return (
      // Customizable Area Start
      <GeneralBlock>
        <Grid container style={{ marginTop:"24px"}} spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={10}>
            <Typography className="label-text">Street</Typography>
            <input data-test-id="street" id="company"
            value={this.state.generalData.street}
             className={this.props.isEdit ? "inputB-edit" : "inputB"}
              type="text" name="companyname" onChange={this.handleStreetInfoChange }></input>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Typography className="label-text">City</Typography>
            <input data-test-id="city" id="company"
            value={this.state.generalData.city}
             className={this.props.isEdit ? "inputB-edit" : "inputB"}
              type="text" name="companyname" onChange={this.handleCityInfoChange }></input>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop:"24px"}}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={10} style={{ display:"flex"}}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box>
              <Typography className="label-text">State<span style={{ color: "red"}}>*</span></Typography>
              <input data-test-id="state" id="company" className={this.props.isEdit ? "inputB-edit" : "inputB"} type="text" name="contact" value={this.state.generalData.contact} onChange={this.handleGeneralInfoChange }></input>
              <Typography className="error-msg">{this.props.fieldRequiredData.contact}</Typography>
            </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box>
              <Typography className="label-text">Country<span style={{ color: "red"}}>*</span></Typography>
              <input data-test-id="country" id="company" className={this.props.isEdit ? "inputB-edit" : "inputB"} type="text" name="country" value={this.state.generalData.country} onChange={this.handleGeneralInfoChange }></input>
              <Typography className="error-msg">{this.props.fieldRequiredData.email}</Typography>
            </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box style={{ marginTop:"24px"}}>
              <Typography className="label-text">Zip code</Typography>
              <input data-test-id="zip" id="company" className={this.props.isEdit ? "inputB-edit" : "inputB"} type="text" name="phonenumber" value={this.state.generalData.zip} onChange={this.handleZipInfoChange }></input>
            </Box>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GeneralBlock>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End