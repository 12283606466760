import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface GeneralData {
  companyname: string;
  // companytype: string;
  selectedComOption: string;
  contact: string;
  email: string;
  phonenumber: string;
  [key: string]: string; 
}

interface FieldRequire {
  companyname: string;
  contact: string;
  email: string;
  phonenumber: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isEdit: boolean;
  general: GeneralData,
  passState:Function,
  fieldRequiredData: FieldRequire
  // Customizable Area End
}

interface S {
//   txtInputValue: string;
//   txtSavedValue: string;
//   enableField: boolean;
    // Customizable Area Start
    generalData:GeneralData;
    showDropDownCom: boolean;
    selectedComOption: string;
    
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class GeneralInfoBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
    //   txtInputValue: "",
    //   txtSavedValue: "A",
    //   enableField: false,
      
            // Customizable Area Start
            generalData: { companyname: "", companytype: "", selectedComOption:"",contact: "", email: "", phonenumber:""},
            showDropDownCom: false,
            selectedComOption: "",

            
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({ generalData: this.props.general, selectedComOption: this.props.general.selectedComOption})
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined){
    if(prevProps.general.companyname !== this.props.general.companyname){  
    this.setState({ generalData: this.props.general, selectedComOption: this.props.general.selectedComOption})
    }
  }

  // Customizable Area Start

  handleGeneralInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({generalData:{...this.state.generalData, [event.target.name]:event.target.value}});
    this.props.passState({ ...this.state.generalData, [event.target.name]: event.target.value, selectedComOption: this.state.selectedComOption });

  }  

  handleDropdownCom = () => {
    this.setState({ showDropDownCom: !this.state.showDropDownCom })
  }

  handleComChange = (event: string) => {
    this.setState({ selectedComOption: event, showDropDownCom: false});
    this.props.passState({...this.state.generalData, selectedComOption:event})
  }; 

 
  // Customizable Area End
}
